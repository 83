<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" md="6" class="pr-2 pb-4">
        <v-card>
          <!-- alert card -->
          <v-card-text class="pb-1">
            <v-alert
              v-if="currentAlert"
              type="error"
              outlined
              class="text-center"
            >
              <span>
                A fall alert was created on
                {{
                  $d(
                    new Date(currentAlert.creationTime.seconds) * 1000,
                    "short"
                  )
                }}.
                <p
                  class="mb-0"
                  v-for="history in currentAlert.historiesList"
                  :key="history.alertId"
                >
                  Notification sent to {{ history.request.user.displayName }} at
                  {{
                    $d(
                      new Date(history.request.user.creationTime.seconds) *
                        1000,
                      "short"
                    )
                  }}
                </p>
                <v-btn text class="mt-3" @click="confirmAcknowledge()">
                  Mark as resolved
                </v-btn>
                <v-dialog
                  v-model="dialog"
                  max-width="400px"
                  transition="dialog-bottom-transition"
                >
                  <v-card>
                    <v-card-title>
                      <span class="text-h5"> Confirm </span>
                    </v-card-title>

                    <v-card-text> Mark this alert as resolved ? </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="acknowledge"
                        :loading="ackLoading"
                      >
                        Acknowledge
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </span>
            </v-alert>

            <v-alert v-else type="success" outlined class="text-center">
              No current alert for this structure
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="pl-2 pb-4">
        <v-card v-if="structure">
          <!-- Presence card -->
          <v-card-text class="pb-1">
            <v-alert
              v-if="structure.presenceStatus == 2"
              type="info"
              outlined
              class="text-center"
            >
              Presence detected at
              {{
                $d(
                  new Date(structure.lastPresenceUpdated.seconds) * 1000,
                  "short"
                )
              }}
            </v-alert>
            <v-alert v-else type="warning" outlined class="text-center">
              No presence detected since
              {{
                $d(
                  new Date(structure.lastPresenceUpdated.seconds) * 1000,
                  "short"
                )
              }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="pb-6">
        <v-card ref="timeCard">
          <v-card-title>
            <v-icon class="pr-3">mdi-clock</v-icon>
            Statistics
          </v-card-title>
          <v-card-subtitle> last 12 hours </v-card-subtitle>
          <v-skeleton-loader
            type="card"
            class="mx-auto"
            :loading="
              isLoading(
                '/msh.service.v1.StructureManager/GetADLStructureEvents'
              )
            "
            transition="scale-transition"
          >
            <span v-if="adl && adl.length > 0">
              <TimeChart :data="adl" :width="width" :height="height" />
            </span>
            <v-card-text v-else> </v-card-text>
          </v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" md="6" class="pr-2 pb-4">
        <!-- Missing skeleton -->
        <v-card min-height="150">
          <v-card-title>
            <v-icon class="pr-3">mdi-clipboard-list-outline</v-icon>
            Latest ADL events
          </v-card-title>
          <v-skeleton-loader
            type="card"
            class="mx-auto"
            :loading="
              isLoading(
                '/msh.service.v1.StructureManager/GetADLStructureEvents'
              )
            "
            transition="scale-transition"
          >
            <v-list two-line v-if="adl && adl.length > 0">
              <v-list-item-group active-class="pink--text" multiple>
                <!-- This used a computed list that holds the last 5 events -->
                <template v-for="(event, index) in shortAdl">
                  <v-list-item :key="event.timestamp">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="getType(event)"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="getSubType(event)"
                        ></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text
                          v-text="getDate(event)"
                        ></v-list-item-action-text>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index < adl.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
            <v-card-text v-else>
              No event captured in the last 12 hours
            </v-card-text>
          </v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="pl-2 pb-4">
        <v-card v-if="structure != null" min-height="150">
          <v-card-title>
            <v-icon class="pr-3">fa-tablet</v-icon>
            {{ $t("msh.aside.devices") }}
          </v-card-title>
          <v-data-table
            :headers="deviceHeaders"
            :items="structure.devicesList"
            hide-default-footer
            class="pb-5"
          >
            <template v-slot:item.isOnline.value="{ item }">
              <span
                :class="item.isOnline.value == false ? 'text--error' : ''"
                >{{ $t("msh.devices.online." + item.isOnline.value) }}</span
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" md="6" class="pr-2 pb-6">
        <chart-card
          v-on:navigate="navigate('activityLevel')"
          :loading="
            isLoading(
              '/msh.service.v1.StructureManager/GetActivityLevelHistory'
            )
          "
          title="Activity Level"
          icon="yoga"
        >
          <template v-if="activity_level.length == 1">
            <v-card-text class="text-center"
              >No activity level captured in the last 24 hours</v-card-text
            >
          </template>
          <template v-else>
            <v-row>
              <v-col cols="12" sm="6" class="">
                <GChart
                  :settings="{ packages: ['line'] }"
                  :data="activity_level.chart_data"
                  :options="activity_level_option"
                  :createChart="(el, google) => new google.charts.Line(el)"
                />
              </v-col>
              <v-col cols="12" sm="6" class="fill-height mx-auto">
                <v-card color="#F8F9FA" class="mr-4 fill-height mx-auto">
                  <v-card-title class="text-subtitle-2"
                    >Latest activity result</v-card-title
                  >
                  <v-card-text
                    >Average to {{ activity_level.average }}</v-card-text
                  >
                  <!-- TODO have level explained ? -->
                  <!-- LEVEL_UNSPECIFIED = 0;
                      LEVEL_ABSENT = 1;
                      LEVEL_MICRO_ACTIVE = 2;
                      LEVEL_LIGHTLY_ACTIVE = 3;
                      LEVEL_MODERATELY_ACTIVE = 4;
                      LEVEL_STRONGLY_ACTIVE = 5; -->
                </v-card>
              </v-col>
            </v-row>
          </template>
        </chart-card>
      </v-col>
      <v-col cols="12" md="6" class="pl-2 pb-6">
        <chart-card
          v-on:navigate="navigate('temp')"
          :loading="
            isLoading('/msh.service.v1.StructureManager/GetTemperatures')
          "
          title="Temperature"
          icon="thermometer"
        >
          <template v-if="temperature && temperature.chart_data.length == 1">
            <v-card-text class="text-center"
              >No temperature event captured in the last 24 hours</v-card-text
            >
          </template>
          <template v-else>
            <v-row>
              <v-col v-if="temperature" cols="12" sm="6" class="pr-2">
                <GChart
                  :settings="{ packages: ['line'] }"
                  :data="temperature.chart_data"
                  :options="temperature_option"
                  :createChart="(el, google) => new google.charts.Line(el)"
                />
              </v-col>
              <v-col
                v-if="temperature"
                cols="12"
                sm="6"
                class="pr-2 fill-height"
              >
                <v-card color="#F8F9FA" class="mr-4 fill-height">
                  <v-card-title class="text-subtitle-2"
                    >Temperature details</v-card-title
                  >
                  <v-card-text
                    >Average to {{ temperature.average }} in the
                    last</v-card-text
                  >
                  <v-card-text
                    >Highest temperature of {{ temperature.highest }} captured
                    at {{ temperature.highest_timestamp }}</v-card-text
                  >
                </v-card>
              </v-col>
            </v-row>
          </template>
        </chart-card>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" md="6" class="pr-2 pb-4">
        <chart-card
          v-on:navigate="navigate('airQuality')"
          :loading="
            isLoading('/msh.service.v1.StructureManager/GetAirQualities')
          "
          title="Air quality"
          icon="home"
        >
          <template v-if="air_quality.length == 1">
            <v-card-text class="text-center"
              >No air quality event captured in the last 24 hours</v-card-text
            >
          </template>
          <template v-else>
            <v-row>
              <v-col cols="12" sm="6" class="pr-2">
                <GChart
                  :data="air_quality"
                  :options="air_quality_option"
                  :settings="{ packages: ['line'] }"
                  :createChart="(el, google) => new google.charts.Line(el)"
                />
              </v-col>
              <v-col cols="12" sm="6" class="pr-2 fill-height">
                <v-card color="#F8F9FA" class="mr-4 fill-height">
                  <v-card-title class="text-subtitle-2"
                    >Air quality details</v-card-title
                  >
                  <v-card-text>Average to ... in the last ...</v-card-text>
                  <v-card-text>missing toggle for value info ...</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </chart-card>
      </v-col>
      <v-col cols="12" md="6" class="pl-2 pb-4">
        <chart-card
          v-on:navigate="navigate('humidity')"
          :loading="isLoading('/msh.service.v1.StructureManager/GetHumidities')"
          title="% Humidity"
          icon="water-percent"
        >
          <template v-if="humidity.length == 1">
            <v-card-text class="text-center"
              >No humidity level captured in the last 24 hours</v-card-text
            >
          </template>
          <template v-else>
            <v-row>
              <v-col cols="12" sm="6" class="pr-2">
                <GChart
                  :settings="{ packages: ['line'] }"
                  :data="humidity"
                  :options="humidity_option"
                  :createChart="(el, google) => new google.charts.Line(el)"
                />
              </v-col>
              <v-col cols="12" sm="6" class="pl-2">
                <v-card color="#F8F9FA" class="mr-4 fill-height">
                  <v-card-title class="text-subtitle-2"
                    >Humidity detail</v-card-title
                  >
                  <v-card-text>Average to ... in the last ...</v-card-text>
                  <v-card-text>missing toggle for value info ...</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </chart-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
<script>
import { mapGetters, mapState } from "vuex";
import store from "@/store";
import router from "@/router";
import ChartCard from "./ChartCard.vue";
import TimeChart from "./TimeChart.vue";
import {
  STRUCTURE_REQUEST,
  STRUCTURE_ALERT_REQUEST,
  STRUCTURE_ADL_REQUEST,
  STRUCTURE_ACTIVITY_LEVEL_REQUEST,
  STRUCTURE_SENSOR_TEMPERATURE_REQUEST,
  STRUCTURE_SENSOR_HUMIDITY_REQUEST,
  STRUCTURE_SENSOR_AIR_QUALITY_REQUEST,
  STRUCTURE_ACKNOWLEDGE_ALERT_REQUEST_PROMISE,
} from "@/store/constants";
import { GChart } from "vue-google-charts";

export default {
  data() {
    return {
      // TODO Only options so far, to check with material change
      temperature_option: {
        chart: {
          title: "",
        },
        legend: {
          position: "none",
        },
      },
      activity_level_option: {
        chart: {
          title: "",
        },
        legend: {
          position: "none",
        },
      },
      air_quality_option: {
        chart: {
          title: "",
        },
        legend: {
          position: "none",
        },
      },
      humidity_option: {
        chart: {
          title: "",
        },
        legend: {
          position: "none",
        },
      },
      // ack confirm dialog state
      dialog: false,
      ackLoading: false,
      // Device data-table
      deviceHeaders: [
        { text: this.$t("msh.devices.label"), value: "label" },
        { text: this.$t("msh.devices.online.title"), value: "isOnline.value" },
      ],
      // TimeChart display attributes
      width: 0,
      height: 350,
      timeout: null,
    };
  },
  components: { GChart, ChartCard, TimeChart },
  mounted() {
    this.width = this.$refs.timeCard.$el.clientWidth;
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    /**
     * Method called on resizing the window
     */
    resizeHandler(e) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        1;
        this.width = this.$refs.timeCard.$el.clientWidth;
      }, 200);
    },
    /**
     * Return the date from the ADL event object
     */
    getDate(event) {
      if (event.fall) {
        return this.$d(new Date(event.fall.timestamp.seconds) * 1000, "short");
      } else if (event.presence) {
        return this.$d(
          new Date(event.presence.timestamp.seconds) * 1000,
          "short"
        );
      }
    },
    /**
     * Return the type from the ADL event object
     */
    getType(event) {
      if (event.fall) {
        return "Fall";
      } else if (event.presence) {
        return "Presence";
      }
    },
    /**
     * Return the additional information from the ADL event object
     */
    getSubType(event) {
      if (event.fall) {
        return "Possible fall detected";
      } else if (event.presence) {
        if (event.presence.status == 1) {
          return "Presence not detected";
        } else if (event.presence.status == 2) {
          return "Presence detected";
        } else {
          return "Could not determine presence status";
        }
      }
    },
    /**
     * Will be use to navigate to the proper page once ready
     */
    navigate(chartName) {
      chartName;
      // router.push({
      //   name: "structureChartDetail",
      //   params: {
      //     buildingId: router.currentRoute.params.buildingId,
      //     structureId: router.currentRoute.params.structureId,
      //     chartName: chartName,
      //   },
      // });
    },
    /**
     * Close the dialog confirm window
     */
    close() {
      this.dialog = false;
    },
    /**
     * Display the dialog confirm window
     */
    confirmAcknowledge() {
      this.dialog = true;
    },
    acknowledge() {
      this.ackLoading = true;
      store
        .dispatch(
          STRUCTURE_ACKNOWLEDGE_ALERT_REQUEST_PROMISE,
          this.currentAlert.alertId
        )
        .then((response) => {
          store.dispatch(STRUCTURE_ALERT_REQUEST, this.structure.structureId);
          this.ackLoading = false;
          this.dialog = false;
        })
        .catch((err) => {
          this.ackLoading = false;
        });
    },
  },
  /**
   * Section require to show and hide the dialog window
   */
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    ...mapState({
      structure: (state) => state.structure.structure,
      currentAlert: (state) => state.structure.structure_alert,
      temperature: (state) => state.structure.structure_temperature,
      activity_level: (state) => state.structure.structure_activity_level,
      humidity: (state) => state.structure.structure_humidities,
      air_quality: (state) => state.structure.structure_air_qualities,
      adl: (state) => state.structure.structure_adl,
    }),
    ...mapGetters(["isLoading"]),
    /**
     * Return a small list of adl
     */
    shortAdl() {
      if (this.adl) {
        return this.adl.slice(0, 5);
      }
      return [];
    },
  },
  /**
   * Before we enter the detail we obtain the structure
   * Using vm in the next function enable us to set the structure in the data section
   */
  async beforeRouteEnter(to, from, next) {
    store.dispatch(STRUCTURE_REQUEST, to.params.structureId);
    store.dispatch(STRUCTURE_ALERT_REQUEST, to.params.structureId);
    store.dispatch(STRUCTURE_ADL_REQUEST, to.params.structureId);
    store.dispatch(STRUCTURE_ACTIVITY_LEVEL_REQUEST, to.params.structureId);
    store.dispatch(STRUCTURE_SENSOR_TEMPERATURE_REQUEST, to.params.structureId);
    store.dispatch(STRUCTURE_SENSOR_HUMIDITY_REQUEST, to.params.structureId);
    store.dispatch(STRUCTURE_SENSOR_AIR_QUALITY_REQUEST, to.params.structureId);
    next();
  },
};
</script>
