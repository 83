<template>
  <v-hover v-slot="{ hover }">
    <v-card v-on:click="$emit('navigate')" min-height="275">
      <v-card-title>
        <v-icon class="pr-3" :disabled="hover">mdi-{{ icon }}</v-icon>
        <span :class="{ 'blue--text': hover }">{{ title }}</span>
      </v-card-title>
      <v-skeleton-loader
        type="card"
        class="mx-auto"
        :loading="loading"
        transition="scale-transition"
      >
        <!-- Main slot that will be show once the loadingFunction is truthful -->
        <slot></slot>
      </v-skeleton-loader>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "chartCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
