<template>
  <svg class="timeseries" :width="width" :height="height">
    <g id="bottom"></g>
  </svg>
</template>
<script>
import * as d3 from "d3";

export default {
  name: "TimeChart",
  props: {
    width: { type: Number },
    height: { type: Number },
    data: { type: Array },
  },
  data() {
    return {
      svg: null,
      events: [],
    };
  },
  computed: {},
  watch: {
    data: "renderSvg",
    width: "renderSvg",
  },
  mounted() {
    this.initSvg();
  },
  methods: {
    initSvg() {
      this.svg = d3
        .select(this.$el)
        .append("g")
        .attr("transform", "translate(20, -30)"); // padding for axis label
      this.renderSvg();
    },
    renderSvg() {
      // Forcing today date
      const today = new Date();
      const yesterday = new Date();
      yesterday.setHours(today.getHours() - 12);

      const axisHeight = this.height - 20;
      if (this.data == null || this.data.length == 0) {
        // exit the render on no data
        return;
      }
      d3.select("#bottom").attr(
        "transform",
        "translate(20, " + axisHeight + ")"
      );

      let fallEvents = [];
      this.events = [];
      let previousPresenceDate = yesterday;
      let lastPresenceStatus = "";
      // Calculate events from data, in reverse since the list we have is from the newest event
      this.data
        .slice()
        .reverse()
        .forEach((eventData) => {
          if (eventData.presence != null) {
            let type = "unknown";
            if (eventData.presence.status == 2) {
              type = "present";
            } else if (eventData.presence.status == 1) {
              type = "absent";
            }
            lastPresenceStatus = type;

            const presenceDate = new Date(
              eventData.presence.timestamp.seconds * 1000
            );
            this.events.push({
              type: type,
              start: previousPresenceDate,
              end: presenceDate,
            });
            previousPresenceDate = presenceDate;
          } else if (eventData.fall != null) {
            const fallDate = new Date(eventData.fall.timestamp.seconds * 1000);

            fallEvents.push({
              type: "fall",
              start: fallDate,
              end: new Date(fallDate.getTime() + 1 * 60000), // adding 5 min to see the event in the chart
            });
          }
        });

      // Last presence value means we keep it until the end of the chart.
      this.events.push({
        type: lastPresenceStatus,
        start: previousPresenceDate,
        end: today,
      });

      // to have fall displayed properly we add it in the end. This means it is on top of the presence rect.
      this.events.push(...fallEvents);

      const scale = d3
        .scaleTime()
        .domain([yesterday, today])
        .range([0, this.width - 50]);

      const selection = this.svg.selectAll("rect").data(this.events);

      selection
        .enter()
        .append("rect")
        .attr("class", (event) => event.type)
        .merge(selection)
        .attr(
          "transform",
          (event) =>
            `translate(${scale(event.start)} ${this.height}) scale(1 -1)`
        )
        .attr("width", (event) => scale(event.end) - scale(event.start))
        .attr("height", this.height);
      selection.exit().remove();

      // bottom axe
      const bottomAxis = d3.axisBottom(scale);
      d3.select("#bottom").call(bottomAxis);
    },
  },
};
</script>
<style scoped>
.timeseries {
  margin: 10px 5px 0px 5px;
}
.timeseries >>> rect.absent {
  fill: #bebebe;
}
.timeseries >>> rect.unknown {
  fill: #a99191;
}
.timeseries >>> rect.present {
  fill: #88b9d6;
}
.timeseries >>> rect.fall {
  fill: #ff1100;
}
</style>
